import { Warning } from "phosphor-react";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useSolicitationStorage } from "./CheckPreviousSolicitationContext";

export function AlreadyHaveLastSolicitaed() {

    const {
        cleanCarroReservaCache,
        cleanSeguroAppCache,
        cleanFuneralCache,
        cleanVidrosCache,
        cleanPetCache,
        cleanPeqReparosCache,
        setAcceptKeepLastCarroReservaSolicitation,
        handleCarroReserva,
        setAcceptKeepLastSeguroAppSolicitation,
        handleAPP,
        setAcceptKeepLastFuneralSolicitation,
        handleFuneralForm,
        setAcceptKeepLastVidrosSolicitation,
        handleVidrosForm,
        setAcceptKeepLastPetSolicitation,
        handlePetForm,
        setAcceptKeepLastPeqReparosSolicitation,
        handleReparosForm

    } = useSolicitationStorage()

    const handleNegativeAnswer = () => {
        switch (localStorage.getItem("lastSolicitatedService")) {
            case "CarroReserva":
                return handleCarroReservaNegativeAnswer()
            case "SeguroAPP":
                return handleSeguroAppNegativeAnswer()
            case "Funeral":
                return handleFuneralNegativeAnswer()
            case "Vidros":
                return handleVidrosNegativeAnswer()
            case "Pet":
                return handlePetNegativeAnswer()
            case "PequenosReparos":
                return handlePeqReparosNegativeAnswer()
            default:
                toast.error("Algo interno deu errado, por favor entre em contato com a equipe interna.")
        }
        
    }

    //handle negative answer

    const handleCarroReservaNegativeAnswer = () => {
        cleanCarroReservaCache()
        handleCarroReserva()
        document.querySelector(".solicitated_alert").style.display = "none"
    }

    const handleSeguroAppNegativeAnswer = () => {
        cleanSeguroAppCache()
        handleAPP()
        document.querySelector(".solicitated_alert").style.display = "none"
    }

    const handleFuneralNegativeAnswer = () => {
        cleanFuneralCache()
        handleFuneralForm()
        document.querySelector(".solicitated_alert").style.display = "none"
    }

    const handleVidrosNegativeAnswer = () => {
        cleanVidrosCache()
        handleVidrosForm()
        document.querySelector(".solicitated_alert").style.display = "none"
    }

    const handlePetNegativeAnswer = () => {
        cleanPetCache()
        handlePetForm()
        document.querySelector(".solicitated_alert").style.display = "none"
    }

    const handlePeqReparosNegativeAnswer = () => {
        cleanPeqReparosCache()
        handleReparosForm()
        document.querySelector(".solicitated_alert").style.display = "none"
    }

    //handle postivies answer

    const handleCarroReservaPositiveAnswer = () => {
        setAcceptKeepLastCarroReservaSolicitation(true)
        handleCarroReserva()
        document.querySelector(".solicitated_alert").style.display = "none"
    }

    const handleSeguroAppPositiveAnswer = () => {
        setAcceptKeepLastSeguroAppSolicitation(true)
        handleAPP()
        document.querySelector(".solicitated_alert").style.display = "none"
    }

    const handleFuneralPositiveAnswer = () => {
        setAcceptKeepLastFuneralSolicitation(true)
        handleFuneralForm()
        document.querySelector(".solicitated_alert").style.display = "none"
    }

    const handleVidrosPositiveAnswer = () => {
        setAcceptKeepLastVidrosSolicitation(true)
        handleVidrosForm()
        document.querySelector(".solicitated_alert").style.display = "none"
    }

    const handlePetPositiveAnswer = () => {
        setAcceptKeepLastPetSolicitation(true)
        handlePetForm()
        document.querySelector(".solicitated_alert").style.display = "none"
    }

    const handlePeqReparosPositiveAnswer = () => {
        setAcceptKeepLastPeqReparosSolicitation(true)
        handleReparosForm()
        document.querySelector(".solicitated_alert").style.display = "none"
    }

    const handlePositiveAnswer = () => {

        switch (localStorage.getItem("lastSolicitatedService")) {
            case "CarroReserva":
                return handleCarroReservaPositiveAnswer()
            case "SeguroAPP":
                return handleSeguroAppPositiveAnswer()
            case "Funeral":
                return handleFuneralPositiveAnswer()
            case "Vidros":
                return handleVidrosPositiveAnswer()
            case "Pet":
                return handlePetPositiveAnswer()
            case "PequenosReparos":
                return handlePeqReparosPositiveAnswer()
            default:
                toast.error("Algo interno deu errado, por favor entre em contato com a equipe interna.")
        }
    }

    const checkLastSolicitedService = () => {
        switch (localStorage.getItem("lastSolicitatedService")) {
            case "CarroReserva":
                return "CARRO RESERVA";
            case "SeguroAPP":
                return "SEGURO APP";
            case "Funeral":
                return "FUNERAL";
            case "Vidros":
                return "VIDROS";
            case "Pet":
                return "PET";
            case "PequenosReparos":
                return "PEQUENOS REPAROS";
            default:
                toast.error("Algo interno deu errado, por favor entre em contato com a equipe interna.")
        }
    }

    return (
        <div className="solicitated_alert">
            <Warning size={40} />
            <p className="mt-4">Identificamos que uma solicitacao de {checkLastSolicitedService()} foi feita recentemente, no CNPJ: {localStorage.getItem("cnpj")}. Gostaria de reutilizar os mesmos dados desta solicitacao?</p>
            <div className="d-flex align-items-center gap-2">
                <button className="fade-in cs-btn cs-style1 cs-type1" type='button' onClick={handlePositiveAnswer}>
                    <span>Sim</span>
                </button>
                <button className="fade-in cs-btn cs-style1 cs-type1" type='button' onClick={handleNegativeAnswer}>
                    <span>Nao</span>
                </button>
            </div>
        </div>
    )
}