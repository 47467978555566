import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import FunFact from '../FunFact';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import { basepath } from '../../App';
import Footer from '../Footer';
import { CustomCardInfo } from '../CustomCardInfo';
import { BadgeDollarSign, BadgePercent, Gift, Pill, ShoppingBasket, Wrench } from 'lucide-react';
import { SideMenu } from '../SideMenu';
import { ClubeForm } from '../ClubeForm';
import { ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';

export default function Rcv() {
    pageTitle('Seguro RC-V');

    const funfaceData = [
        {
            title: 'Usuários no Clube de Descontos',
            factNumber: '2.5M',
        },
        {
            title: 'Diárias de carro reserva liberadas todo o mês',
            factNumber: '15K',
        },
        {
            title: 'Novos segurados de vida todo mês',
            factNumber: '1.4K',
        },
        {
            title: 'Atendimentos de assistência residencial todo mês',
            factNumber: '33K',
        },
    ];

    const logos = [
        {
            id: 1,
            logo: `${basepath}/img/parceiros/cinemark_logo.svg`
        },
        {
            id: 2,
            logo: `${basepath}/img/parceiros/dominos_logo.svg`
        },
        {
            id: 3,
            logo: `${basepath}/img/parceiros/estacio_logo.svg`
        },
        {
            id: 4,
            logo: `${basepath}/img/parceiros/magalu_logo.svg`
        },
        {
            id: 5,
            logo: `${basepath}/img/parceiros/marisa_logo.svg`
        },
        {
            id: 6,
            logo: `${basepath}/img/parceiros/netshoes_logo.svg`
        },
    ]

    const promoItems = [
        {
            id: 1,
            icon: <BadgePercent color="#FF4A17" size={40} />,
            title: "Como é calculado o custo?",
            description: "O custo do seguro de Responsabilidade Civil Veículos é calculado por tipo de viagem."
        },
        {
            id: 2,
            icon: <Wrench color="#FF4A17" size={40} />,
            title: "O que fazer para acionar um sinistro?",
            description: "Entre em contato com a Kovr pelo telefone: 0800 000 8785, que está disponível 24h por dia, nos 7 dias da semana."
        }
    ]

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <PageHeading
                title="Seguro RC-V"
                bgSrc={`${basepath}/images/rcv1.png`}
                pageLinkText="Seguro RC-V"
            />

            <SideMenu />
            <ToastContainer />
            <Spacing lg="150" md="80" />
            <Div className="container">
                <Div className="row">
                    <Div className="col-xl-5 col-lg-7">
                        <SectionHeading
                            title="Conheça mais sobre o RC-V"
                            subtitle="O que é"
                        >
                            <Spacing lg="30" md="30" />
                            <p className='text-justify'>
                                O seguro RC-V da KOVR tem faturamento mensal, considerando as averbações enviadas via AT&M. A apólice define um custo por viagem, de acordo com:
                            </p>
                            <ul>
                                <li>Transporte dentro do mesmo estado</li>
                                <li>Transporte entre estados da mesma região</li>
                                <li>Transporte entre estados de regiões diferentes</li>
                            </ul>
                            <Div className="cs-separator cs-accent_bg"></Div>
                            <Spacing lg="25" md="40" />
                            <div className='d-flex align-items-center gap-4'>
                                <a href={`${basepath}/CG_RC_Veicular.pdf`} download>
                                    <button className="fade-in cs-btn cs-style1 cs-type1" type='button' onClick={null}>
                                        <span>Condições Gerais</span>
                                    </button>
                                </a>
                                <a href={`${basepath}/QAR.xlsx`} download>
                                    <button className="fade-in cs-btn cs-style1 cs-type1" type='button' onClick={null}>
                                        <span>Questionário de Risco</span>
                                    </button>
                                </a>
                            </div>
                        </SectionHeading>
                    </Div>
                    <Div className="col-lg-5 pt-5 offset-xl-2">
                        <img className='rounded' src={`${basepath}/images/rcv2.png`} alt="Seguro RC-V" />
                        <Spacing lg="25" md="25" />
                    </Div>
                </Div>
            </Div>

            <Spacing lg="150" md="80" />
            <Div className="container">
                <h3 className="text-center text-secondary cs-section_subtitle">Dúvidas</h3>
                <h2 className="text-center cs-section_title mb-5">Como funciona?</h2>
                <Div className="row justify-content-center mt-5 pt-5">
                    {promoItems.map(item => (
                        <Div key={item.id} className="col-md-4 mb-5">
                            <div className='text-center d-flex flex-column align-items-center gap-3'>
                                {item.icon}
                                <h5>{item.title}</h5>
                                <p>{item.description}</p>
                            </div>
                        </Div>
                    ))}
                </Div>
            </Div>

            <Spacing lg="150" md="80" />
            <Div className="container">
                <Cta
                    title="Conquistar um novo associado é muito bom, fidelizá-lo é muito melhor!"
                    btnText="Solicitar serviço!"

                    bgSrc={`${basepath}/img/_1.webp`}
                />
            </Div>

            <Footer />
        </>
    );
}
