import Div from "../Div";
import Spacing from "../Spacing";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { CheckCircle } from "phosphor-react";
import InputMask from "react-input-mask"
import { useSolicitationStorage } from "../CheckPreviousSolicitationContext";
import { FileInputPreview } from "./FileInputPreview";
import { Loading } from "../loading";
import { Search } from "lucide-react";


export function SeguroAppForm() {

    const { keepLastSeguroApp } = useSolicitationStorage()
    const [appSolicitationForm, setSolicitationForm] = useState(true)
    const [sendingButton, setSendingButton] = useState("Solicitar benefício")
    const [seguroAppStatus, setseguroAppStatus] = useState(null)

    const [isSearchingCep, setIsSearchingCep] = useState(false)
    const [isSearchingCnpj, setIsSearchingCnpj] = useState(false)
    const [isSearchingVehicule, setIsSearchingVehicule] = useState(false)

    const [eidField, setEidField] = useState(null)
    const [APPsolicitanteEmpresa, setAPPSolicitanteEmpresa] = useState(null)

    const [APPstates, setAPPStates] = useState([])
    const [APPcities, setAPPCities] = useState([])

    //campos formulario
    const [APPcnpj, setAPPCnpj] = useState(null)
    const [APPsolicitante, setAPPSolicitante] = useState(null)
    const [APPnomeSolicitante, setAPPnomeSolicitante] = useState(null)
    const [APPtelefone, setAPPTelefone] = useState(null)
    const [APPemail, setAPPEmail] = useState(null)
    const [APPcelularCliente, setAPPcelularCliente] = useState(null)
    const [APPidentidaderCliente, setAPPidentidaderCliente] = useState(null)
    const [APPcpfCliente, setAPPcpfCliente] = useState(null)
    const [APPemailCliente, setAPPemailCliente] = useState(null)

    const [APPcep, setAPPCep] = useState(null)
    const [APPlogradouro, setAPPLogradouro] = useState(null)
    const [APPbairro, setAPPBairro] = useState(null)
    const [APPcidade, setAPPCidade] = useState(null)
    const [APPestado, setAPPEstado] = useState(null)
    const [APPnumero, setAPPNumero] = useState(null)
    const [APPcomplemento, setAPPComplemento] = useState(null)

    const [hasSecondDriver, setHasSecondDriver] = useState(false)
    const [APPnomeSegundoCondutor, setAPPnomeSegundoCondutor] = useState(null)
    const [APPcpfSegundoCondutor, setAPPcpfSegundoCondutor] = useState(null)
    const [APPtelefoneSegundoCondutor, setAPPtelefoneSegundoCondutor] = useState(null)
    const [APPemailSegundoCondutor, setAPPemailSegundoCondutor] = useState(null)

    const [APPplaca, setAPPplaca] = useState(null)
    const [APPmarcas, setAPPmarcas] = useState([])
    const [APPmodelos, setAPPmodelos] = useState([])

    const [APPmodelo, setAPPmodelo] = useState(null)
    const [APPmarca, setAPPmarca] = useState(null)
    const [APPanoModelo, setAPPanoModelo] = useState(null)

    const [APPchassi, setAPPchassi] = useState(null)
    const [APPrenavam, setAPPrenavam] = useState(null)
    const [APPlotacao, setAPPlotacao] = useState(null)
    const [APPutilizacao, setAPPutilizacao] = useState(null)
    const [APPcobertura, setAPPcobertura] = useState(null)
    const [APPcapitalSegurado, setAPPcapitalSegurado] = useState(null)

    const [dutFile, setDutFile] = useState()
    const [boFile, setBoFile] = useState()
    const [rgFile, setRgFile] = useState()
    const [cpfFile, setCpfFile] = useState()
    const [cnhFile, setCnhFile] = useState()
    const [certObitoFile, setCertObitoFile] = useState()

    const fetchMarcas = async () => {
        await fetch("https://nvo.brasilbeneficios.club/api/get-marcas")
            .then((res) => res.json())
            .then((data) => {
                setAPPmarcas(data.data)
            })
    }

    function handleMarcaChange(e) {
        setIsSearchingVehicule(true)
        fetch(`https://nvo.brasilbeneficios.club/api/get-modelos/${e.target.value}`)
            .then((res) => res.json())
            .then((data) => {
                setAPPmodelos(data.data.Modelos)
                setAPPmarca(e.target.options[e.target.selectedIndex].text)
            })
            .finally(() => setIsSearchingVehicule(false))
    }

    useEffect(() => {
        fetchMarcas()
    }, [])

    const handleCEP = (target) => {
        setAPPCep(target.value)
        if (target.value.length != 9 && target.value.length != 0) {
            // toast.error("Campo CEP invalido.")
        } else {
            setIsSearchingCep(true)
            fetch(`https://nvo.brasilbeneficios.club/api/get-addr/${target.value.replace(/-/g, '')}`)
                .then((res) => res.json())
                .then((data) => {
                    const response = data.data
                    setAPPCep(target.value)
                    setAPPLogradouro(response.rua)
                    setAPPBairro(response.bairro)
                    setAPPCidade(response.cidade)
                    setAPPEstado(response.estado)
                })
                .catch(err => toast.error("Existe algo errado com o CEP."))
                .finally(() => setIsSearchingCep(false))
        }
    }

    const handleCNPJ = () => {

        if (APPcnpj.length != 18 && APPcnpj.length != 0) {
            toast.error("Campo CNPJ invalido.")
        } else {

            setIsSearchingCnpj(true)

            async function postJSON(data) {
                try {
                    const response = await fetch("https://brasilbeneficios.club/bb/f-empresa", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(data),
                    });

                    const result = await response.json();
                    setEidField(result.IDCodigo)
                    setAPPEmail(result.Email)
                    setAPPSolicitante(result.RazaoSocial)
                    setAPPTelefone(result.Telefone)
                    setAPPSolicitanteEmpresa(result.PessoaContato)

                } catch (error) {
                    toast.error("CNPJ invalido ou empresa nao encontrada.")
                }
            }

            postJSON({ "CPFCNPJ": APPcnpj })

            setIsSearchingCnpj(false)

        }

    }

    const handleAPPSubmit = async (event) => {
        event.preventDefault();
        setSendingButton("Enviando...")

        const formData = new FormData(event.currentTarget);

        fetch("https://brasilbeneficios.club/bb/area-cliente/app/send", {
            method: "POST",
            mode: "no-cors",
            credentials: "same-origin",
            body: formData
        })
            .then((res) => {

                toast.success("Solicitação enviada com sucesso. Em breve, nossa equipe entrará em contato!")

                    for (const data of formData.entries()) {
                        localStorage.setItem(data[0], data[1])
                    }

                    setseguroAppStatus("Sended")
                    localStorage.setItem("lastSolicitatedService", "SeguroAPP")
            })
    }

    return (
        <>
            {seguroAppStatus === null ?
                <Div className="fade-in col-lg-8">
                    {keepLastSeguroApp ? (
                        <>
                            {appSolicitationForm ? (
                                <form onSubmit={handleAPPSubmit} method="post" encType="multipart/form-data">
                                    <input type="hidden" name="IDEmpresa" value={localStorage.getItem("IDEmpresa")} />
                                    <Div className="row">
                                        <Div className="col-lg-6">
                                            <div className="d-flex align-items-center gap-4">
                                                <label className="form_field_label">CNPJ:</label>
                                                {isSearchingCnpj && (
                                                    <div className="mb-2">
                                                        <Loading size="2px" />
                                                    </div>
                                                )}
                                            </div>
                                            <InputMask
                                                mask="99.999.999/9999-99"
                                                maskChar=""
                                                placeholder="__.___.___/____-__"
                                                onChange={(e) => setAPPCnpj(e.target.value)}
                                                onBlur={handleCNPJ}
                                                value={localStorage.getItem("CNPJEmpresa")}
                                            >
                                                {(inputProps) => <input {...inputProps} name="CNPJEmpresa" className="cs-form_field" required />}
                                            </InputMask>
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">Nome:</label>
                                            <input type="text" onChange={(e) => setAPPnomeSolicitante(e.target.value)} name="Nome" value={localStorage.getItem("Nome")} className="cs-form_field" required />
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">Solicitante:</label>
                                            <input type="text" onChange={(e) => setAPPSolicitante(e.target.value)} name="SolicitanteEmpresa" value={localStorage.getItem("SolicitanteEmpresa")} className="cs-form_field" required />
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">Telefone:</label>
                                            <InputMask
                                                mask="(99) 9 9999-9999"
                                                maskChar=""
                                                placeholder="(__) _ ____-____"
                                                onChange={(e) => setAPPTelefone(e.target.value)}
                                                value={localStorage.getItem("TelefoneEmpresa")}
                                            >
                                                {(inputProps) => <input {...inputProps} name="TelefoneEmpresa" className="cs-form_field" required />}
                                            </InputMask>
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">E-mail:</label>
                                            <input type="email" onChange={(e) => setAPPEmail(e.target.value)} name="EmailEmpresa" value={localStorage.getItem("EmailEmpresa")} className="cs-form_field" required />
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">Celular do cliente:</label>
                                            <InputMask
                                                mask="(99) 9 9999-9999"
                                                maskChar=""
                                                placeholder="(__) _ ____-____"
                                                onChange={(e) => setAPPcelularCliente(e.target.value)}
                                                value={localStorage.getItem("TelefoneComercial")}
                                            >
                                                {(inputProps) => <input {...inputProps} name="TelefoneComercial" className="cs-form_field" required />}
                                            </InputMask>
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">RG do cliente:</label>
                                            <InputMask
                                                mask="aa 99-999-999"
                                                maskChar=""
                                                placeholder="UF __-___-___"
                                                onChange={(e) => setAPPidentidaderCliente(e.target.value)}
                                                value={localStorage.getItem("RG")}
                                            >
                                                {(inputProps) => <input {...inputProps} name="RG" className="cs-form_field" required />}
                                            </InputMask>
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">CPF do cliente:</label>
                                            <InputMask
                                                mask="999.999.999-99"
                                                maskChar=""
                                                placeholder="___.___.___-__"
                                                onChange={(e) => setAPPcpfCliente(e.target.value)}
                                                value={localStorage.getItem("CPF")}
                                            >
                                                {(inputProps) => <input {...inputProps} name="CPF" className="cs-form_field" required />}
                                            </InputMask>
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">E-mail do cliente:</label>
                                            <input type="email" onChange={(e) => setAPPemailCliente(e.target.value)} name="Email" value={localStorage.getItem("Email")} className="cs-form_field" required />
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                    </Div>
                                    <Div className="row">
                                        <Div className="col-lg-4">
                                            <label className="form_field_label">CEP:</label>
                                            <InputMask
                                                mask="99999-999"
                                                maskChar=""
                                                placeholder="_____-___"
                                                onChange={(e) => setAPPCep(e.target.value)}
                                                value={localStorage.getItem("CEP")}
                                            >
                                                {(inputProps) => <input {...inputProps} name="CEP" className="cs-form_field" required />}
                                            </InputMask>
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-2 d-flex flex-column justify-content-end pb-1">
                                            <button className="searchButton" onClick={handleCEP} disabled={isSearchingCep ? true : false} type="button">
                                                {isSearchingCep ? <Loading /> : <Search size={20} />}
                                            </button>
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                    </Div>
                                    <Div className="row fade-in">
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">Logradouro:</label>
                                            <input type="text" value={localStorage.getItem("Logradouro")} name="Logradouro" className="cs-form_field" required />
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">Bairro:</label>
                                            <input type="text" value={localStorage.getItem("Bairro")} name="Bairro" className="cs-form_field" required />
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">Cidade:</label>
                                            <input type="text" value={localStorage.getItem("Cidade")} name="Cidade" className="cs-form_field" required />
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">Estado:</label>
                                            <input type="text" value={localStorage.getItem("UF")} name="UF" className="cs-form_field" required />
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">Numero:</label>
                                            <input type="text" onChange={(e) => setAPPNumero(e.target.value)} value={localStorage.getItem("Numero")} name="Numero" className="cs-form_field" required />
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">Complemento:</label>
                                            <input type="text" onChange={(e) => setAPPComplemento(e.target.value)} value={localStorage.getItem("Complemento")} name="Complemento" className="cs-form_field" required />
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                    </Div>
                                    <Div className="row">
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">Possui segundo condutor?</label>
                                            <div class="radio-button-container">
                                                <div class="radio-button">
                                                    <input type="radio" class="radio-button__input" onChange={() => setHasSecondDriver(true)} checked={localStorage.getItem("possuicondutorsecundario") === true} id="hasDriverYes" name="possuicondutorsecundario" />
                                                    <label class="radio-button__label" for="hasDriverYes">
                                                        <span class="radio-button__custom"></span>
                                                        Sim
                                                    </label>
                                                </div>
                                                <div class="radio-button">
                                                    <input type="radio" class="radio-button__input" onChange={() => setHasSecondDriver(false)} checked={localStorage.getItem("possuicondutorsecundario") === false} id="hasDriverTwo" name="possuicondutorsecundario" />
                                                    <label class="radio-button__label" for="hasDriverTwo">
                                                        <span class="radio-button__custom"></span>
                                                        Nao
                                                    </label>
                                                </div>
                                            </div>
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        {localStorage.getItem("possuiSegundoCondutor") && (
                                            <Div className="row">
                                                <Div className="col-lg-6">
                                                    <label className="form_field_label">Nome do Condutor Secundário:</label>
                                                    <input type="text" onChange={(e) => setAPPnomeSegundoCondutor(e.target.value)} value={localStorage.getItem("Nome2")} name="Nome2" className="cs-form_field" required />
                                                    <Spacing lg="20" md="20" />
                                                </Div>
                                                <Div className="col-lg-6">
                                                    <label className="form_field_label">CPF do Condutor Secundário:</label>
                                                    <InputMask
                                                        mask="999.999.999-99"
                                                        maskChar=""
                                                        placeholder="___.___.___-__"
                                                        onChange={(e) => setAPPcpfSegundoCondutor(e.target.value)}
                                                        value={localStorage.getItem("CPF2")}
                                                    >
                                                        {(inputProps) => <input {...inputProps} name="CPF2" className="cs-form_field" required />}
                                                    </InputMask>
                                                    <Spacing lg="20" md="20" />
                                                </Div>
                                                <Div className="col-lg-6">
                                                    <label className="form_field_label">Telefone do Condutor Secundário:</label>
                                                    <InputMask
                                                        mask="(99) 9 9999-9999"
                                                        maskChar=""
                                                        placeholder="(__) _ ____-____"
                                                        onChange={(e) => setAPPtelefoneSegundoCondutor(e.target.value)}
                                                        value={localStorage.getItem("Telefone2")}
                                                    >
                                                        {(inputProps) => <input {...inputProps} name="Telefone2" className="cs-form_field" required />}
                                                    </InputMask>
                                                    <Spacing lg="20" md="20" />
                                                </Div>
                                                <Div className="col-lg-6">
                                                    <label className="form_field_label">E-mail do Condutor Secundário:</label>
                                                    <input type="email" onChange={(e) => setAPPemailSegundoCondutor(e.target.value)} name="Email2" value={localStorage.getItem("Email2")} className="cs-form_field" required />
                                                    <Spacing lg="20" md="20" />
                                                </Div>
                                            </Div>
                                        )}
                                    </Div>
                                    <Div className="row">
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">Placa:</label>
                                            <InputMask
                                                mask="aaa-9*99"
                                                maskChar=""
                                                placeholder="___-____"
                                                onChange={(e) => setAPPplaca(e.target.value)}
                                                value={localStorage.getItem("Placa")}
                                            >
                                                {(inputProps) => <input {...inputProps} name="Placa" className="cs-form_field" required />}
                                            </InputMask>
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">Marca do veículo:</label>
                                            <select onChange={(e) => handleMarcaChange(e)} name="Montadora" className="cs-form_field" required>
                                                <option value="">Selecione uma marca</option>
                                                {APPmarcas.map(marca => {
                                                    return (
                                                        <option key={marca.Value} value={marca.Value} selected={localStorage.getItem("Montadora") === marca.value ? true : false} >{marca.Label}</option>
                                                    )
                                                })}
                                            </select>
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <div className="d-flex align-items-center gap-4">
                                                <label className="form_field_label">Modelo do veículo:</label>
                                                {isSearchingVehicule && (
                                                    <div className="mb-2">
                                                        <Loading size="2px" />
                                                    </div>
                                                )}
                                            </div>
                                            <select className="cs-form_field" required name="Modelo" disabled={isSearchingVehicule ? true : false}>
                                                <option value="">Selecione um modelo</option>
                                                {APPmodelos.map(modelo => {
                                                    return (
                                                        <option key={modelo.Value} value={modelo.Value} selected={localStorage.getItem("Modelo") === modelo.value ? true : false}>{modelo.Label}</option>
                                                    )
                                                })}
                                            </select>
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">Ano modelo do veiculo:</label>
                                            <input type="text" value={localStorage.getItem("Ano")} name="Ano" className="cs-form_field" required />
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <div className="d-flex align-items-center gap-4">
                                                <label className="form_field_label">Categoria do veiculo:</label>
                                            </div>
                                            <select className="cs-form_field" required name="Categoria">
                                                <option value="">Selecione uma categoria</option>
                                                <option value="Automóvel" selected={localStorage.getItem("Categoria") === "Automóvel" ? true : false} >Automóvel</option>
                                                <option value="Caminhão" selected={localStorage.getItem("Categoria") === "Caminhão" ? true : false}>Caminhão</option>
                                                <option value="Ônibus" selected={localStorage.getItem("Categoria") === "Ônibus" ? true : false}>Ônibus</option>
                                                <option value="Micro-ônibus/Van" selected={localStorage.getItem("Categoria") === "Micro-ônibus/Van" ? true : false}>Micro-ônibus/Van</option>
                                            </select>
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">Chassi:</label>
                                            <input type="text" onChange={(e) => setAPPchassi(e.target.value)} name="Chassi" value={localStorage.getItem("Chassi")} className="cs-form_field" required />
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">Renavam:</label>
                                            <input type="text" onChange={(e) => setAPPrenavam(e.target.value)} name="Renavam" value={localStorage.getItem("Renavam")} className="cs-form_field" required />
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">Lotaçao máxima:</label>
                                            <input type="text" onChange={(e) => setAPPlotacao(e.target.value)} name="LotacaoMaxima" value={localStorage.getItem("LotacaoMaxima")} className="cs-form_field" required />
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">Utilizacao:</label>
                                            <select onChange={(e) => setAPPutilizacao(e.target.value)} name="Utilizacao" className="cs-form_field" required>
                                                <option value="">Selecione uma opcao</option>
                                                <option value="Motorista de Aplicativo" selected={localStorage.getItem("Utilizacao") === "Motorista de Aplicativo" ? true : false} >Motorista de Aplicativo</option>
                                                <option value="Passeio" selected={localStorage.getItem("Utilizacao") === "Passeio" ? true : false}>Passeio</option>
                                                <option value="Taxi" selected={localStorage.getItem("Utilizacao") === "Taxi" ? true : false}>Taxi</option>
                                                <option value="Transporte de passageiro" selected={localStorage.getItem("Utilizacao") === "Transporte de passageiro" ? true : false}>Transporte de passageiros</option>
                                                <option value="Locaçao" selected={localStorage.getItem("Utilizacao") === "Locaçao" ? true : false}>Locaçao</option>
                                            </select>
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">Cobertura:</label>
                                            <select onChange={(e) => setAPPcobertura(e.target.value)} name="Cobertura" className="cs-form_field" required>
                                                <option value="">Selecione uma cobertura</option>
                                                <option value="Morte Acidental (MA)" selected={localStorage.getItem("Cobertura") === "Morte Acidental (MA)" ? true : false} >Morte Acidental (MA)</option>
                                                <option value="Invalidez Permanente por Acidente (IPA" selected={localStorage.getItem("Cobertura") === "Morte Acidental (MA)" ? true : false}>Invalidez Permanente por Acidente (IPA)</option>
                                                <option value="MA e IPA" selected={localStorage.getItem("Cobertura") === "MA e IPA" ? true : false}>MA e IPA</option>
                                                <option value="MA e DMH" selected={localStorage.getItem("Cobertura") === "MA e DMH" ? true : false}>MA e DMH</option>
                                                <option value="IPA e DMH" selected={localStorage.getItem("Cobertura") === "IPA e DMH" ? true : false}>IPA e DMH</option>
                                                <option value="MA, IPA e DMH" selected={localStorage.getItem("Cobertura") === "MA, IPA e DMH" ? true : false}>MA, IPA e DMH</option>
                                            </select>
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                    </Div>
                                    {APPlotacao != null && (
                                        <button className="fade-in cs-btn cs-style1 cs-type1" type='submit'>
                                            <span>{sendingButton}</span>
                                        </button>
                                    )}
                                </form>
                            ) : (
                                <form onSubmit={handleAPPSubmit} method="post" encType="multipart/form-data">
                                    <Div className="row">
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">CNPJ:</label>
                                            <InputMask
                                                mask="99.999.999/9999-99"
                                                maskChar=""
                                                placeholder="__.___.___/____-__"
                                                onChange={(e) => setAPPCnpj(e.target.value)}
                                                onBlur={handleCNPJ}
                                            >
                                                {(inputProps) => <input {...inputProps} name="cnpj" className="cs-form_field" required />}
                                            </InputMask>
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">Nome Associado:</label>
                                            <input type="text" onChange={(e) => setAPPSolicitante(e.target.value)} name="nome_associado" value={APPsolicitante ?? ""} className="cs-form_field" required />
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">CPF:</label>
                                            <InputMask
                                                mask="999.999.999-99"
                                                maskChar=""
                                                placeholder="___.___.___-__"
                                                onChange={(e) => setAPPcpfCliente(e.target.value)}
                                            >
                                                {(inputProps) => <input {...inputProps} name="cpf_associado" className="cs-form_field" required />}
                                            </InputMask>
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">Placa do veículo:</label>
                                            <InputMask
                                                mask="aaa-9999"
                                                maskChar=""
                                                placeholder="___-____"
                                                onChange={(e) => setAPPplaca(e.target.value)}
                                            >
                                                {(inputProps) => <input {...inputProps} name="placa" className="cs-form_field" required />}
                                            </InputMask>
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">Solicitar cobertura:</label>
                                            <select className="cs-form_field" required onChange={(e) => setAPPcobertura(e.target.value)} name="cobertura">
                                                <option value="default">Selecione uma cobertura</option>
                                                <option value="MA">MA</option>
                                                <option value="IPA">IPA</option>
                                                <option value="DMHO">DMHO</option>
                                            </select>
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                    </Div>
                                    <Div className="row">
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">Atestado de Obito:</label>
                                            <FileInputPreview file={dutFile} setFile={setDutFile} />
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">B.O:</label>
                                            <FileInputPreview file={boFile} setFile={setBoFile} />
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">RG:</label>
                                            <FileInputPreview file={rgFile} setFile={setRgFile} />
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">CPF:</label>
                                            <FileInputPreview file={cpfFile} setFile={setCpfFile} />
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">CNH:</label>
                                            <FileInputPreview file={cnhFile} setFile={setCnhFile} />
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">Certidão de Óbito em caso de morte:</label>
                                            <FileInputPreview file={certObitoFile} setFile={setCertObitoFile} />
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                    </Div>
                                    {APPcobertura != null && (
                                        <button className="fade-in cs-btn cs-style1 cs-type1" type='submit'>
                                            <span>{sendingButton}</span>
                                        </button>
                                    )}
                                </form>
                            )}
                        </>
                    ) : (
                        <>
                            {appSolicitationForm ? (
                                <form onSubmit={handleAPPSubmit} method="post" encType="multipart/form-data">
                                    <input type="hidden" name="IDEmpresa" value={eidField} />
                                    <inpu type="hidden" name="NomeEmpresa" value={APPsolicitanteEmpresa} />
                                    <Div className="row">
                                        <Div className="col-lg-6">
                                            <div className="d-flex align-items-center gap-4">
                                                <label className="form_field_label">CNPJ:</label>
                                                {isSearchingCnpj && (
                                                    <div className="mb-2">
                                                        <Loading size="2px" />
                                                    </div>
                                                )}
                                            </div>
                                            <InputMask
                                                mask="99.999.999/9999-99"
                                                maskChar=""
                                                placeholder="__.___.___/____-__"
                                                onChange={(e) => setAPPCnpj(e.target.value)}
                                                onBlur={handleCNPJ}
                                            >
                                                {(inputProps) => <input {...inputProps} name="CNPJEmpresa" className="cs-form_field" required />}
                                            </InputMask>
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">Nome:</label>
                                            <input type="text" onChange={(e) => setAPPnomeSolicitante(e.target.value)} name="Nome" value={APPnomeSolicitante ?? ""} className="cs-form_field" required />
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">Solicitante:</label>
                                            <input type="text" onChange={(e) => setAPPSolicitante(e.target.value)} name="SolicitanteEmpresa" value={APPsolicitante ?? ""} className="cs-form_field" required />
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">Telefone:</label>
                                            <InputMask
                                                mask="(99) 9 9999-9999"
                                                maskChar=""
                                                placeholder="(__) _ ____-____"
                                                onChange={(e) => setAPPTelefone(e.target.value)}
                                                value={APPtelefone ?? ""}
                                            >
                                                {(inputProps) => <input {...inputProps} name="TelefoneEmpresa" className="cs-form_field" required />}
                                            </InputMask>
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">E-mail:</label>
                                            <input type="email" onChange={(e) => setAPPEmail(e.target.value)} name="EmailEmpresa" value={APPemail ?? ""} className="cs-form_field" required />
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">Celular do cliente:</label>
                                            <InputMask
                                                mask="(99) 9 9999-9999"
                                                maskChar=""
                                                placeholder="(__) _ ____-____"
                                                onChange={(e) => setAPPcelularCliente(e.target.value)}
                                                value={APPcelularCliente ?? ""}
                                            >
                                                {(inputProps) => <input {...inputProps} name="TelefoneComercial" className="cs-form_field" required />}
                                            </InputMask>
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">RG do cliente:</label>
                                            <InputMask
                                                mask="aa 99-999-999"
                                                maskChar=""
                                                placeholder="UF __-___-___"
                                                onChange={(e) => setAPPidentidaderCliente(e.target.value)}
                                                value={APPidentidaderCliente ?? ""}
                                            >
                                                {(inputProps) => <input {...inputProps} name="RG" className="cs-form_field" required />}
                                            </InputMask>
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">CPF do cliente:</label>
                                            <InputMask
                                                mask="999.999.999-99"
                                                maskChar=""
                                                placeholder="___.___.___-__"
                                                onChange={(e) => setAPPcpfCliente(e.target.value)}
                                                value={APPcpfCliente ?? ""}
                                            >
                                                {(inputProps) => <input {...inputProps} name="CPF" className="cs-form_field" required />}
                                            </InputMask>
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">E-mail do cliente:</label>
                                            <input type="email" onChange={(e) => setAPPemailCliente(e.target.value)} name="Email" value={APPemailCliente ?? ""} className="cs-form_field" required />
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                    </Div>
                                    <Div className="row">
                                        <Div className="col-lg-4">
                                            <label className="form_field_label">CEP:</label>
                                            <InputMask
                                                mask="99999-999"
                                                maskChar=""
                                                placeholder="_____-___"
                                                onChange={(e) => handleCEP(e.target)}
                                            >
                                                {(inputProps) => <input {...inputProps} name="CEP" className="cs-form_field" required />}
                                            </InputMask>
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-2 d-flex flex-column justify-content-end pb-1">
                                            <button className="searchButton" onClick={handleCEP} disabled={isSearchingCep ? true : false} type="button">
                                                {isSearchingCep ? <Loading /> : <Search size={20} />}
                                            </button>
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                    </Div>
                                    {APPcep && (
                                        <Div className="row fade-in">
                                            <Div className="col-lg-6">
                                                <label className="form_field_label">Logradouro:</label>
                                                <input type="text" value={APPlogradouro} onChange={(e) => setAPPLogradouro(e.target.value)} name="Logradouro" className="cs-form_field" required />
                                                <Spacing lg="20" md="20" />
                                            </Div>
                                            <Div className="col-lg-6">
                                                <label className="form_field_label">Bairro:</label>
                                                <input type="text" value={APPbairro}  onChange={(e) => setAPPBairro(e.target.value)} name="Bairro" className="cs-form_field" required />
                                                <Spacing lg="20" md="20" />
                                            </Div>
                                            <Div className="col-lg-6">
                                                <label className="form_field_label">Cidade:</label>
                                                <input type="text" value={APPcidade} onChange={(e) => setAPPCidade(e.target.value)} name="Cidade" className="cs-form_field" required />
                                                <Spacing lg="20" md="20" />
                                            </Div>
                                            <Div className="col-lg-6">
                                                <label className="form_field_label">Estado:</label>
                                                <input type="text" value={APPestado} onChange={(e) => setAPPEstado(e.target.value)} name="UF" className="cs-form_field" required />
                                                <Spacing lg="20" md="20" />
                                            </Div>
                                            <Div className="col-lg-6">
                                                <label className="form_field_label">Numero:</label>
                                                <input type="text" onChange={(e) => setAPPNumero(e.target.value)} name="Numero" className="cs-form_field" required />
                                                <Spacing lg="20" md="20" />
                                            </Div>
                                            <Div className="col-lg-6">
                                                <label className="form_field_label">Complemento:</label>
                                                <input type="text" onChange={(e) => setAPPComplemento(e.target.value)} name="Complemento" className="cs-form_field" required />
                                                <Spacing lg="20" md="20" />
                                            </Div>
                                        </Div>
                                    )}
                                    {(APPnumero) && (
                                        <>
                                            <Div className="row">
                                                <Div className="col-lg-6">
                                                    <label className="form_field_label">Possui segundo condutor?</label>
                                                    <div class="radio-button-container">
                                                        <div class="radio-button">
                                                            <input type="radio" class="radio-button__input" onChange={() => setHasSecondDriver(true)} checked={hasSecondDriver === true} id="hasDriverYes" name="possuicondutorsecundario" />
                                                            <label class="radio-button__label" for="hasDriverYes">
                                                                <span class="radio-button__custom"></span>
                                                                Sim
                                                            </label>
                                                        </div>
                                                        <div class="radio-button">
                                                            <input type="radio" class="radio-button__input" onChange={() => setHasSecondDriver(false)} checked={hasSecondDriver === false} id="hasDriverTwo" name="possuicondutorsecundario" />
                                                            <label class="radio-button__label" for="hasDriverTwo">
                                                                <span class="radio-button__custom"></span>
                                                                Nao
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <Spacing lg="20" md="20" />
                                                </Div>
                                                {hasSecondDriver && (
                                                    <Div className="row">
                                                        <Div className="col-lg-6">
                                                            <label className="form_field_label">Nome do Condutor Secundário:</label>
                                                            <input type="text" onChange={(e) => setAPPnomeSegundoCondutor(e.target.value)} value={APPnomeSegundoCondutor ?? ""} name="Nome2" className="cs-form_field" required />
                                                            <Spacing lg="20" md="20" />
                                                        </Div>
                                                        <Div className="col-lg-6">
                                                            <label className="form_field_label">CPF do Condutor Secundário:</label>
                                                            <InputMask
                                                                mask="999.999.999-99"
                                                                maskChar=""
                                                                placeholder="___.___.___-__"
                                                                onChange={(e) => setAPPcpfSegundoCondutor(e.target.value)}
                                                                value={APPcpfSegundoCondutor ?? ""}
                                                            >
                                                                {(inputProps) => <input {...inputProps} name="CPF2" className="cs-form_field" required />}
                                                            </InputMask>
                                                            <Spacing lg="20" md="20" />
                                                        </Div>
                                                        <Div className="col-lg-6">
                                                            <label className="form_field_label">Telefone do Condutor Secundário:</label>
                                                            <InputMask
                                                                mask="(99) 9 9999-9999"
                                                                maskChar=""
                                                                placeholder="(__) _ ____-____"
                                                                onChange={(e) => setAPPtelefoneSegundoCondutor(e.target.value)}
                                                                value={APPtelefoneSegundoCondutor ?? ""}
                                                            >
                                                                {(inputProps) => <input {...inputProps} name="Telefone2" className="cs-form_field" required />}
                                                            </InputMask>
                                                            <Spacing lg="20" md="20" />
                                                        </Div>
                                                        <Div className="col-lg-6">
                                                            <label className="form_field_label">E-mail do Condutor Secundário:</label>
                                                            <input type="email" onChange={(e) => setAPPemailSegundoCondutor(e.target.value)} name="Email2" value={APPemailSegundoCondutor ?? ""} className="cs-form_field" required />
                                                            <Spacing lg="20" md="20" />
                                                        </Div>
                                                    </Div>
                                                )}
                                            </Div>
                                            <Div className="row">
                                                <Div className="col-lg-6">
                                                    <label className="form_field_label">Placa:</label>
                                                    <InputMask
                                                        mask="aaa-9*99"
                                                        maskChar=""
                                                        placeholder="___-____"
                                                        onChange={(e) => setAPPplaca(e.target.value)}
                                                    >
                                                        {(inputProps) => <input {...inputProps} name="Placa" className="cs-form_field" required />}
                                                    </InputMask>
                                                    <Spacing lg="20" md="20" />
                                                </Div>
                                                <Div className="col-lg-6">
                                                    <label className="form_field_label">Marca do veículo:</label>
                                                    <input type="hidden" name="Montadora" value={APPmarca} />
                                                    <select onChange={(e) => handleMarcaChange(e)} name="" className="cs-form_field" required>
                                                        <option value="">Selecione uma marca</option>
                                                        {APPmarcas.map(marca => {
                                                            return (
                                                                <option key={marca.Value} value={marca.Value}>{marca.Label}</option>
                                                            )
                                                        })}
                                                    </select>
                                                    <Spacing lg="20" md="20" />
                                                </Div>
                                                <Div className="col-lg-6">
                                                    <div className="d-flex align-items-center gap-4">
                                                        <label className="form_field_label">Modelo do veículo:</label>
                                                        {isSearchingVehicule && (
                                                            <div className="mb-2">
                                                                <Loading size="2px" />
                                                            </div>
                                                        )}
                                                    </div>
                                                    <input type="hidden" name="Modelo" value={APPmodelo} />
                                                    <select onChange={(e) => setAPPmodelo(e.target.options[e.target.selectedIndex].text)} className="cs-form_field" required name="" disabled={isSearchingVehicule ? true : false}>
                                                        <option value="">Selecione um modelo</option>
                                                        {APPmodelos.map(modelo => {
                                                            return (
                                                                <option key={modelo.Value} value={modelo.Value}>{modelo.Label}</option>
                                                            )
                                                        })}
                                                    </select>
                                                    <Spacing lg="20" md="20" />
                                                </Div>
                                                <Div className="col-lg-6">
                                                    <label className="form_field_label">Ano modelo do veiculo:</label>
                                                    <input type="text" onChange={(e) => setAPPanoModelo(e.target.value)} name="Ano" className="cs-form_field" required />
                                                    <Spacing lg="20" md="20" />
                                                </Div>
                                                <Div className="col-lg-6">
                                                    <div className="d-flex align-items-center gap-4">
                                                        <label className="form_field_label">Categoria do veiculo:</label>
                                                    </div>
                                                    <select className="cs-form_field" required name="Categoria">
                                                        <option value="">Selecione uma categoria</option>
                                                        <option value="Automóvel">Automóvel</option>
                                                        <option value="Caminhão">Caminhão</option>
                                                        <option value="Ônibus">Ônibus</option>
                                                        <option value="Micro-ônibus/Va">Micro-ônibus/Van</option>
                                                    </select>
                                                    <Spacing lg="20" md="20" />
                                                </Div>
                                                <Div className="col-lg-6">
                                                    <label className="form_field_label">Chassi:</label>
                                                    <input type="text" onChange={(e) => setAPPchassi(e.target.value)} name="Chassi" value={APPchassi ?? ""} className="cs-form_field" required />
                                                    <Spacing lg="20" md="20" />
                                                </Div>
                                                <Div className="col-lg-6">
                                                    <label className="form_field_label">Renavam:</label>
                                                    <input type="text" onChange={(e) => setAPPrenavam(e.target.value)} name="Renavam" value={APPrenavam ?? ""} className="cs-form_field" required />
                                                    <Spacing lg="20" md="20" />
                                                </Div>
                                                <Div className="col-lg-6">
                                                    <label className="form_field_label">Lotaçao máxima:</label>
                                                    <input type="text" onChange={(e) => setAPPlotacao(e.target.value)} name="LotacaoMaxima" value={APPlotacao ?? ""} className="cs-form_field" required />
                                                    <Spacing lg="20" md="20" />
                                                </Div>
                                                <Div className="col-lg-6">
                                                    <label className="form_field_label">Utilizacao:</label>
                                                    <select onChange={(e) => setAPPutilizacao(e.target.value)} name="Utilizacao" className="cs-form_field" required>
                                                        <option value="">Selecione uma opcao</option>
                                                        <option value="Motorista de Aplicativo">Motorista de Aplicativo</option>
                                                        <option value="Passeio">Passeio</option>
                                                        <option value="Taxi">Taxi</option>
                                                        <option value="Transporte de passageiro">Transporte de passageiros</option>
                                                        <option value="Locaçao">Locaçao</option>
                                                    </select>
                                                    <Spacing lg="20" md="20" />
                                                </Div>
                                                <Div className="col-lg-6">
                                                    <label className="form_field_label">Cobertura:</label>
                                                    <select onChange={(e) => setAPPcobertura(e.target.value)} name="Cobertura" className="cs-form_field" required>
                                                        <option value="">Selecione uma cobertura</option>
                                                        <option value="Morte Acidental (MA)">Morte Acidental (MA)</option>
                                                        <option value="Invalidez Permanente por Acidente (IPA">Invalidez Permanente por Acidente (IPA)</option>
                                                        <option value="MA e IPA">MA e IPA</option>
                                                        <option value="MA e DMH">MA e DMH</option>
                                                        <option value="IPA e DMH">IPA e DMH</option>
                                                        <option value="MA, IPA e DMH">MA, IPA e DMH</option>
                                                    </select>
                                                    <Spacing lg="20" md="20" />
                                                </Div>
                                                <Div className="col-lg-6">
                                                    <label className="form_field_label">Capital Segurado:</label>
                                                    <select onChange={(e) => setAPPcapitalSegurado(e.target.value)} name="CapitalSegurado" className="cs-form_field" required>
                                                        <option value="">Selecione um capital</option>
                                                        <option value="R$10.000,00">R$10.000,00</option>
                                                        <option value="R$20.000,00">R$20.000,00</option>
                                                        <option value="R$30.000,00">R$30.000,00</option>
                                                        <option value="R$40.000,00">R$40.000,00</option>
                                                        <option value="R$50.000,00">R$50.000,00</option>
                                                        <option value="R$60.000,00">R$60.000,00</option>
                                                        <option value="R$70.000,00">R$70.000,00</option>
                                                    </select>
                                                    <Spacing lg="20" md="20" />
                                                </Div>
                                            </Div>
                                            {APPlotacao != null && (
                                                <button className="fade-in cs-btn cs-style1 cs-type1" type='submit'>
                                                    <span>{sendingButton}</span>
                                                </button>
                                            )}
                                        </>
                                    )}
                                </form>
                            ) : (
                                <form onSubmit={handleAPPSubmit} method="post" encType="multipart/form-data">
                                    <Div className="row">
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">CNPJ:</label>
                                            <InputMask
                                                mask="99.999.999/9999-99"
                                                maskChar=""
                                                placeholder="__.___.___/____-__"
                                                onChange={(e) => setAPPCnpj(e.target.value)}
                                                onBlur={handleCNPJ}
                                            >
                                                {(inputProps) => <input {...inputProps} name="cnpj" className="cs-form_field" required />}
                                            </InputMask>
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">Nome Associado:</label>
                                            <input type="text" onChange={(e) => setAPPSolicitante(e.target.value)} name="nome_associado" value={APPsolicitante ?? ""} className="cs-form_field" required />
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">CPF:</label>
                                            <InputMask
                                                mask="999.999.999-99"
                                                maskChar=""
                                                placeholder="___.___.___-__"
                                                onChange={(e) => setAPPcpfCliente(e.target.value)}
                                            >
                                                {(inputProps) => <input {...inputProps} name="cpf_associado" className="cs-form_field" required />}
                                            </InputMask>
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">Placa do veículo:</label>
                                            <InputMask
                                                mask="aaa-9999"
                                                maskChar=""
                                                placeholder="___-____"
                                                onChange={(e) => setAPPplaca(e.target.value)}
                                            >
                                                {(inputProps) => <input {...inputProps} name="placa" className="cs-form_field" required />}
                                            </InputMask>
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">Solicitar cobertura:</label>
                                            <select className="cs-form_field" required onChange={(e) => setAPPcobertura(e.target.value)} name="cobertura">
                                                <option value="default">Selecione uma cobertura</option>
                                                <option value="MA">MA</option>
                                                <option value="IPA">IPA</option>
                                                <option value="DMHO">DMHO</option>
                                            </select>
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                    </Div>
                                    <Div className="row">
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">Atestado de Obito:</label>
                                            <FileInputPreview file={dutFile} setFile={setDutFile} />
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">B.O:</label>
                                            <FileInputPreview file={boFile} setFile={setBoFile} />
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">RG:</label>
                                            <FileInputPreview file={rgFile} setFile={setRgFile} />
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">CPF:</label>
                                            <FileInputPreview file={cpfFile} setFile={setCpfFile} />
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">CNH:</label>
                                            <FileInputPreview file={cnhFile} setFile={setCnhFile} />
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                        <Div className="col-lg-6">
                                            <label className="form_field_label">Certidão de Óbito em caso de morte:</label>
                                            <FileInputPreview file={certObitoFile} setFile={setCertObitoFile} />
                                            <Spacing lg="20" md="20" />
                                        </Div>
                                    </Div>
                                    {APPcobertura != null && (
                                        <button className="fade-in cs-btn cs-style1 cs-type1" type='submit'>
                                            <span>{sendingButton}</span>
                                        </button>
                                    )}
                                </form>
                            )}
                        </>
                    )}

                </Div>
                :
                <Div>
                    <CheckCircle size={50} />
                    <h5 className="mt-3">Sucesso!</h5>
                    <p>Sua solicitação foi realizada com sucesso!<br />Em até 48 horas entraremos em contato para fazer a liberação do seu seguro!</p>
                    <button className="fade-in cs-btn cs-style1 cs-type1" type='button' onClick={(e) => window.location.reload()}>
                        <span>Realizar outra solicitacao</span>
                    </button>
                </Div>}
        </>
    )
}