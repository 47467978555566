import Div from "../Div";
import Spacing from "../Spacing";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { CheckCircle } from "phosphor-react";
import InputMask from "react-input-mask"
import { useSolicitationStorage } from "../CheckPreviousSolicitationContext";
import { Loading } from "../loading";
import { Search } from "lucide-react";
import { FileInputPreview } from "./FileInputPreview";


export function CarroReservaForm() {

    const { keepLastCarroReserva } = useSolicitationStorage()
    const [sendingButton, setSendingButton] = useState("Solicitar benefício")
    const [carroReservaStatus, setCarroReservaStatus] = useState(null)

    const [isSearchingCnpj, setIsSearchingCnpj] = useState(false)
    const [isSearchingCep, setIsSearchingCep] = useState(false)
    const [isSearchingCity, setIsSearchingCity] = useState(false)

    const [eidField, setEidField] = useState(null)

    const [CRstates, setCRStates] = useState([])
    const [CRcities, setCRCities] = useState([])

    const [CRcnpj, setCRCnpj] = useState(null)
    const [CRsolicitante, setCRSolicitante] = useState(null)
    const [CRsolicitanteEmpresa, setCRSolicitanteEmpresa] = useState(null)
    const [CRsolicitanteTelefone, setCRSolicitanteTelefone] = useState(null)
    const [CRsolicitanteEmail, setCRSolicitanteEmail] = useState(null)

    const [CRisRefatorado, setCRisRefatorado] = useState(1)

    const [CRclienteNome, setCRClienteNome] = useState(null)
    const [CRclienteCpf, setCRClienteCpf] = useState(null)
    const [CRclienteEmail, setCRClienteEmail] = useState(null)
    const [CRclienteTelefone, setCRClienteTelefone] = useState(null)

    const [CRcep, setCRCep] = useState(null)
    const [CRlogradouro, setCRLogradouro] = useState(null)
    const [CRbairro, setCRBairro] = useState(null)
    const [CRcidade, setCRCidade] = useState(null)
    const [CRestado, setCREstado] = useState(null)
    const [CRnumero, setCRNumero] = useState(null)
    const [CRcomplemento, setCRComplemento] = useState(null)
    const [CRplaca, setCRPlaca] = useState(null)
    const [CRestadoReserva, setCREstadoReserva] = useState(null)
    const [CRcidadeReserva, setCRCidadeReserva] = useState(null)
    const [CRdataRetirada, setCRDataRetirada] = useState(null)
    const [CRhoraRetirada, setCRHoraRetirada] = useState(null)
    const [CRresponsavelLocacao, setCRResponsavelLocacao] = useState(null)
    const [CRcpfResponsavel, setCRCpfResponsavel] = useState(null)
    const [CRdiarias, setCRDiarias] = useState(null)

    const [CRavisoSinistro, setCRavisoSinistro] = useState()
    const [CRBoletimOcorrencia, setCRBoletimOcorrencia] = useState()
    const [CRcrlv, setCRcrlv] = useState()
    const [CRcnh, setCRcnh] = useState()
    const [CRautorizacaoreparo, setCRautorizacaoreparo] = useState()

    const fetchStates = async () => {

        await fetch("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
            .then((res) => res.json())
            .then((data) => setCRStates(data))

    }

    function handleStateChange(e) {
        setIsSearchingCity(true)
        fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${e.target.value}/municipios`)
            .then((res) => res.json())
            .then((data) => setCRCities(data))
            .finally(() => setIsSearchingCity(false))
    }

    useEffect(() => {
        fetchStates()
    }, [])

    const handleCEP = (target) => {
        setCRCep(target.value)
        if (target.value.length != 9 && target.value.length != 0) {
            // toast.error("Campo CEP invalido.")
        } else {
            setIsSearchingCep(true)
            fetch(`https://nvo.brasilbeneficios.club/api/get-addr/${target.value.replace(/-/g, '')}`)
                .then((res) => res.json())
                .then((data) => {
                    const response = data.data
                    setCRLogradouro(response.rua)
                    setCRBairro(response.bairro)
                    setCRCidade(response.cidade)
                    setCREstado(response.estado)
                })
                .catch(err => toast.error("Existe algo errado com o CEP."))
                .finally(() => setIsSearchingCep(false))
        }
    }

    const handleCNPJ = () => {

        if (CRcnpj.length != 18 && CRcnpj.length != 0) {
            toast.error("Campo CNPJ invalido.")
        } else {

            setIsSearchingCnpj(true)

            async function postJSON(data) {
                try {
                    const response = await fetch("https://brasilbeneficios.club/bb/f-empresa", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(data),
                    });

                    const result = await response.json();

                    if (result.Refaturado === "1") {
                        setCRisRefatorado(1)
                    } else {
                        setCRisRefatorado(0)
                    }

                    setEidField(result.IDCodigo)
                    setCRSolicitanteEmail(result.Email)
                    setCRSolicitante(result.RazaoSocial)
                    setCRSolicitanteTelefone(result.Telefone)
                    setCRSolicitanteEmpresa(result.PessoaContato)

                } catch (error) {
                    toast.error("CNPJ invalido ou empresa nao encontrada.")
                }
            }

            postJSON({ "CPFCNPJ": CRcnpj })

            setIsSearchingCnpj(false)

        }
    }

    const handleCarroReservaSubmit = async (event) => {
        event.preventDefault();
        setSendingButton("Enviando...")

        const formData = new FormData(event.currentTarget);

        fetch("https://brasilbeneficios.club/bb/area-cliente/carro-reserva/send", {
            method: "POST",
            mode: "no-cors",
            credentials: "same-origin",
            body: formData
        })
        .then((res) => {

            toast.success("Solicitação enviada com sucesso. Em breve, nossa equipe entrará em contato!")

            for (const data of formData.entries()) {
                localStorage.setItem(data[0], data[1])
            }

            setCarroReservaStatus("Sended")
            localStorage.setItem("lastSolicitatedService", "CarroReserva")

        })

    }

    return (
        <>
            {carroReservaStatus === null ?
                <Div className="fade-in col-lg-6">
                    {keepLastCarroReserva ? (
                        <form onSubmit={handleCarroReservaSubmit} method="post" encType="multipart/form-data">
                            <input type="file" className="d-none" name="SinistroEmpresa" value="" />
                            <input type="file" className="d-none" name="BoletimOcorrencia" value="" />
                            <input type="file" className="d-none" name="CRLVVeiculo" value="" />
                            <input type="file" className="d-none" name="CHNCliente" value="" />
                            <input type="file" className="d-none" name="AutorizacaoReparo" value="" />
                            <input type="hidden" name="IDEmpresa" value={localStorage.getItem("IDEmpresa")} />
                            <input type="hidden" name="SolicitanteEmpresa" value={localStorage.getItem("SolicitanteEmpresa")} />
                            <Div className="row">
                                <Div className="col-lg-6">
                                    <div className="d-flex align-items-center gap-4">
                                        <label className="form_field_label">CNPJ:</label>
                                        {isSearchingCnpj && (
                                            <div className="mb-2">
                                                <Loading size="2px" />
                                            </div>
                                        )}
                                    </div>
                                    <InputMask
                                        mask="99.999.999/9999-99"
                                        maskChar=""
                                        placeholder="__.___.___/____-__"
                                        onChange={(e) => setCRCnpj(e.target.value)}
                                        onBlur={handleCNPJ}
                                        value={localStorage.getItem("CNPJEmpresa")}
                                    >
                                        {(inputProps) => <input {...inputProps} name="CNPJEmpresa" className="cs-form_field" required />}
                                    </InputMask>
                                    <Spacing lg="20" md="20" />
                                </Div>
                                <Div className="col-lg-6">
                                    <label className="form_field_label">Solicitante:</label>
                                    <input type="text" onChange={(e) => setCRSolicitante(e.target.value)} name="SolicitanteEmpresa" value={localStorage.getItem("NomeEmpresa")} className="cs-form_field" required />
                                    <Spacing lg="20" md="20" />
                                </Div>
                                <Div className="col-lg-6">
                                    <label className="form_field_label">Telefone:</label>
                                    <InputMask
                                        mask="(99) 9 9999-9999"
                                        maskChar=""
                                        placeholder="(__) _ ____-____"
                                        onChange={(e) => setCRSolicitanteTelefone(e.target.value)}
                                        value={localStorage.getItem("TelefoneEmpresa")}
                                    >
                                        {(inputProps) => <input {...inputProps} name="TelefoneEmpresa" className="cs-form_field" required />}
                                    </InputMask>
                                    <Spacing lg="20" md="20" />
                                </Div>
                                <Div className="col-lg-6">
                                    <label className="form_field_label">E-mail:</label>
                                    <input type="email" onChange={(e) => setCRSolicitanteEmail(e.target.value)} value={localStorage.getItem("EmailEmpresa")} name="EmailEmpresa" className="cs-form_field" required />
                                    <Spacing lg="20" md="20" />
                                </Div>
                                <Div className="col-lg-6">
                                    <label className="form_field_label">Nome (Cliente):</label>
                                    <input type="text" onChange={(e) => setCRClienteNome(e.target.value)} name="Nome" value={localStorage.getItem("Nome")} className="cs-form_field" required />
                                    <Spacing lg="20" md="20" />
                                </Div>
                                <Div className="col-lg-6">
                                    <label className="form_field_label">CPF (Cliente):</label>
                                    <InputMask
                                        mask="999.999.999-99"
                                        maskChar=""
                                        placeholder="___.___.___-__"
                                        onChange={(e) => setCRClienteCpf(e.target.value)}
                                        value={localStorage.getItem("CPF")}
                                    >
                                        {(inputProps) => <input {...inputProps} name="CPF" className="cs-form_field" required />}
                                    </InputMask>
                                    <Spacing lg="20" md="20" />
                                </Div>
                                <Div className="col-lg-6">
                                    <label className="form_field_label">E-mail (Cliente):</label>
                                    <input type="email" onChange={(e) => setCRClienteEmail(e.target.value)} name="Email" value={localStorage.getItem("Email")} className="cs-form_field" required />
                                    <Spacing lg="20" md="20" />
                                </Div>
                                <Div className="col-lg-6">
                                    <label className="form_field_label">Telefone (Cliente):</label>
                                    <InputMask
                                        mask="(99) 9 9999-9999"
                                        maskChar=""
                                        placeholder="(__) _ ____-____"
                                        onChange={(e) => setCRClienteTelefone(e.target.value)}
                                        value={localStorage.getItem("Telefone")}
                                    >
                                        {(inputProps) => <input {...inputProps} name="Telefone" className="cs-form_field" required />}
                                    </InputMask>
                                    <Spacing lg="20" md="20" />
                                </Div>
                            </Div>
                            <Div className="row">
                                <Div className="col-lg-4">
                                    <label className="form_field_label">CEP:</label>
                                    <InputMask
                                        mask="99999-999"
                                        maskChar=""
                                        placeholder="_____-___"
                                        onChange={(e) => setCRCep(e.target.value)}
                                        value={localStorage.getItem("CEP")}
                                    >
                                        {(inputProps) => <input {...inputProps} name="CEP" className="cs-form_field" required />}
                                    </InputMask>
                                    <Spacing lg="20" md="20" />
                                </Div>
                                <Div className="col-lg-2 d-flex flex-column justify-content-end pb-1">
                                    <button className="searchButton" onClick={handleCEP} type="button" disabled={isSearchingCep ? true : false}>
                                        {isSearchingCep ? <Loading /> : <Search size={20} />}
                                    </button>
                                    <Spacing lg="20" md="20" />
                                </Div>
                            </Div>
                            <Div className="row fade-in">
                                <Div className="col-lg-6">
                                    <label className="form_field_label">Logradouro:</label>
                                    <input type="text" disabled value={localStorage.getItem("Logradouro")} name="Logradouro" className="cs-form_field" required />
                                    <Spacing lg="20" md="20" />
                                </Div>
                                <Div className="col-lg-6">
                                    <label className="form_field_label">Bairro:</label>
                                    <input type="text" disabled value={localStorage.getItem("Bairro")} name="Bairro" className="cs-form_field" required />
                                    <Spacing lg="20" md="20" />
                                </Div>
                                <Div className="col-lg-6">
                                    <label className="form_field_label">Cidade:</label>
                                    <input type="text" disabled value={localStorage.getItem("Cidade")} name="Cidade" className="cs-form_field" required />
                                    <Spacing lg="20" md="20" />
                                </Div>
                                <Div className="col-lg-6">
                                    <label className="form_field_label">Estado:</label>
                                    <input type="text" disabled value={localStorage.getItem("Estado")} name="Estado" className="cs-form_field" required />
                                    <Spacing lg="20" md="20" />
                                </Div>
                                <Div className="col-lg-6">
                                    <label className="form_field_label">Numero:</label>
                                    <input type="text" onChange={(e) => setCRNumero(e.target.value)} value={localStorage.getItem("Numero")} name="Numero" className="cs-form_field" required />
                                    <Spacing lg="20" md="20" />
                                </Div>
                                <Div className="col-lg-6">
                                    <label className="form_field_label">Complemento:</label>
                                    <input type="text" onChange={(e) => setCRComplemento(e.target.value)} value={localStorage.getItem("Complemento")} name="Complemento" className="cs-form_field" required />
                                    <Spacing lg="20" md="20" />
                                </Div>
                            </Div>
                            <Div className="row">
                                <Div className="col-lg-6">
                                    <label className="form_field_label">Placa:</label>
                                    <InputMask
                                        mask="aaa-9*99"
                                        maskChar=""
                                        placeholder="___-____"
                                        onChange={(e) => setCRPlaca(e.target.value)}
                                        value={localStorage.getItem("Placa")}
                                    >
                                        {(inputProps) => <input {...inputProps} name="Placa" className="cs-form_field" required />}
                                    </InputMask>
                                    <Spacing lg="20" md="20" />
                                </Div>
                                <Div className="col-lg-6">
                                    <label className="form_field_label">Estado:</label>
                                    <select onChange={(e) => handleStateChange(e)} name="EstadoRetirada" className="cs-form_field" required>
                                        <option value="">Selecione um Estado</option>
                                        {CRstates.map(state => {
                                            return (
                                                <option key={state.id} value={state.sigla} selected={localStorage.getItem("EstadoRetirada") === state.sigla ? true : false}>{state.nome}</option>
                                            )
                                        })}
                                    </select>
                                    <Spacing lg="20" md="20" />
                                </Div>
                                <Div className="col-lg-6">
                                    <div className="d-flex align-items-center gap-4">
                                        <label className="form_field_label">Cidade:</label>
                                        {isSearchingCity && (
                                            <div className="mb-2">
                                                <Loading size="2px" />
                                            </div>
                                        )}
                                    </div>
                                    <select onChange={(e) => setCRCidadeReserva(e.target.value)} disabled={isSearchingCity ? true : false} name="CidadeRetirada" className="cs-form_field" required>
                                        <option value="">Selecione uma Cidade</option>
                                        {CRcities.map(city => {
                                            return (
                                                <option key={city.id} value={city.sigla} selected={localStorage.getItem("CidadeRetirada") === city.sigla ? true : false}>{city.nome}</option>
                                            )
                                        })}
                                    </select>
                                    <Spacing lg="20" md="20" />
                                </Div>
                                <Div className="col-lg-6">
                                    <label className="form_field_label">Data Retirada:</label>
                                    <InputMask
                                        mask="99/99/9999"
                                        maskChar=""
                                        placeholder="__/__/____"
                                        onChange={(e) => setCRDataRetirada(e.target.value)}
                                        value={localStorage.getItem("DataRetirada")}
                                    >
                                        {(inputProps) => <input {...inputProps} name="DataRetirada" className="cs-form_field" required />}
                                    </InputMask>
                                    <Spacing lg="20" md="20" />
                                </Div>
                                <Div className="col-lg-6">
                                    <label className="form_field_label">Hora Retirada:</label>
                                    <InputMask
                                        mask="99:99"
                                        maskChar=""
                                        placeholder="__:__"
                                        onChange={(e) => setCRHoraRetirada(e.target.value)}
                                        value={localStorage.getItem("HoraRetirada")}
                                    >
                                        {(inputProps) => <input {...inputProps} name="HoraRetirada" className="cs-form_field" required />}
                                    </InputMask>
                                    <Spacing lg="20" md="20" />
                                </Div>
                                <Div className="col-lg-6">
                                    <label className="form_field_label">Nome do Responsavel pela Locacao:</label>
                                    <input type="text" onChange={(e) => setCRResponsavelLocacao(e.target.value)} value={localStorage.getItem("NomeResponsavelCartao")} name="NomeResponsavelCartao" className="cs-form_field" required />
                                    <Spacing lg="20" md="20" />
                                </Div>
                                <Div className="col-lg-6">
                                    <label className="form_field_label">CPF (Responsavel pela Locacao):</label>
                                    <InputMask
                                        mask="999.999.999-99"
                                        maskChar=""
                                        placeholder="___.___.___-__"
                                        onChange={(e) => setCRCpfResponsavel(e.target.value)}
                                        value={localStorage.getItem("CPFResponsavelCartao")}
                                    >
                                        {(inputProps) => <input {...inputProps} name="CPFResponsavelCartao" className="cs-form_field" required />}
                                    </InputMask>
                                    <Spacing lg="20" md="20" />
                                </Div>
                                <Div className="col-lg-6">
                                    <label className="form_field_label">Diárias:</label>
                                    <input type="number" onChange={(e) => setCRDiarias(e.target.value)} value={localStorage.getItem("QuantidadeDeDiarias")} name="QuantidadeDeDiarias" className="cs-form_field" required />
                                    <Spacing lg="20" md="20" />
                                </Div>
                            </Div>
                            <button className="fade-in cs-btn cs-style1 cs-type1" type='submit'>
                                <span>{sendingButton}</span>
                            </button>
                        </form>
                    ) : (
                        <form onSubmit={handleCarroReservaSubmit} method="post" encType="multipart/form-data">
                            <input type="file" className="d-none" name="SinistroEmpresa" value="" />
                            <input type="file" className="d-none" name="BoletimOcorrencia" value="" />
                            <input type="file" className="d-none" name="CRLVVeiculo" value="" />
                            <input type="file" className="d-none" name="CHNCliente" value="" />
                            <input type="file" className="d-none" name="AutorizacaoReparo" value="" />
                            <input type="hidden" name="IDEmpresa" value={eidField} />
                            <input type="hidden" name="SolicitanteEmpresa" value={CRsolicitanteEmpresa} />
                            <Div className="row">
                                <Div className="col-lg-6">
                                    <div className="d-flex align-items-center gap-4">
                                        <label className="form_field_label">CNPJ:</label>
                                        {isSearchingCnpj && (
                                            <div className="mb-2">
                                                <Loading size="2px" />
                                            </div>
                                        )}
                                    </div>
                                    <InputMask
                                        mask="99.999.999/9999-99"
                                        maskChar=""
                                        placeholder="__.___.___/____-__"
                                        onChange={(e) => setCRCnpj(e.target.value)}
                                        onBlur={handleCNPJ}
                                    >
                                        {(inputProps) => <input {...inputProps} required name="CNPJEmpresa" className="cs-form_field" />}
                                    </InputMask>
                                    <Spacing lg="20" md="20" />
                                </Div>
                                <Div className="col-lg-6">
                                    <label className="form_field_label">Solicitante (Empresa):</label>
                                    <input type="text" onChange={(e) => setCRSolicitante(e.target.value)} name="SolicitanteEmpresa" value={CRsolicitante ?? ""} className="cs-form_field" required/>
                                    <Spacing lg="20" md="20" />
                                </Div>
                                <Div className="col-lg-6">
                                    <label className="form_field_label">Telefone:</label>
                                    <InputMask
                                        mask="(99) 9 9999-9999"
                                        maskChar=""
                                        placeholder="(__) _ ____-____"
                                        onChange={(e) => setCRSolicitanteTelefone(e.target.value)}
                                        value={CRsolicitanteTelefone ?? ""}
                                    >
                                        {(inputProps) => <input {...inputProps} name="TelefoneEmpresa"  required className="cs-form_field" />}
                                    </InputMask>
                                    <Spacing lg="20" md="20" />
                                </Div>
                                <Div className="col-lg-6">
                                    <label className="form_field_label">E-mail:</label>
                                    <input type="email" onChange={(e) => setCRSolicitanteEmail(e.target.value)} name="EmailEmpresa" value={CRsolicitanteEmail ?? ""} className="cs-form_field" required />
                                    <Spacing lg="20" md="20" />
                                </Div>
                                <Div className="col-lg-6">
                                    <label className="form_field_label">Nome (Cliente):</label>
                                    <input type="text" onChange={(e) => setCRClienteNome(e.target.value)} name="Nome" value={CRclienteNome ?? ""} className="cs-form_field" required />
                                    <Spacing lg="20" md="20" />
                                </Div>
                                <Div className="col-lg-6">
                                    <label className="form_field_label">CPF (Cliente):</label>
                                    <InputMask
                                        mask="999.999.999-99"
                                        maskChar=""
                                        placeholder="___.___.___-__"
                                        onChange={(e) => setCRClienteCpf(e.target.value)}
                                    >
                                        {(inputProps) => <input {...inputProps} name="CPF" className="cs-form_field" required />}
                                    </InputMask>
                                    <Spacing lg="20" md="20" />
                                </Div>
                                <Div className="col-lg-6">
                                    <label className="form_field_label">E-mail (Cliente):</label>
                                    <input type="email" onChange={(e) => setCRClienteEmail(e.target.value)} name="Email" value={CRclienteEmail ?? ""} className="cs-form_field" required />
                                    <Spacing lg="20" md="20" />
                                </Div>
                                <Div className="col-lg-6">
                                    <label className="form_field_label">Telefone (Cliente):</label>
                                    <InputMask
                                        mask="(99) 9 9999-9999"
                                        maskChar=""
                                        placeholder="(__) _ ____-____"
                                        onChange={(e) => setCRClienteTelefone(e.target.value)}
                                        value={CRclienteTelefone ?? ""}
                                    >
                                        {(inputProps) => <input {...inputProps} name="Telefone" className="cs-form_field" required />}
                                    </InputMask>
                                    <Spacing lg="20" md="20" />
                                </Div>
                            </Div>
                            <Div className="row">
                                <Div className="col-lg-4">
                                    <label className="form_field_label">CEP:</label>
                                    <InputMask
                                        mask="99999-999"
                                        maskChar=""
                                        placeholder="_____-___"
                                        onChange={(e) => handleCEP(e.target)}
                                    >
                                        {(inputProps) => <input {...inputProps} name="CEP" className="cs-form_field" required />}
                                    </InputMask>
                                    <Spacing lg="20" md="20" />
                                </Div>
                                <Div className="col-lg-2 d-flex flex-column justify-content-end pb-1">
                                    <button className="searchButton" onClick={handleCEP} type="button" disabled={isSearchingCep ? true : false}>
                                        {isSearchingCep ? <Loading /> : <Search size={20} />}
                                    </button>
                                    <Spacing lg="20" md="20" />
                                </Div>
                            </Div>
                            {CRcep && (
                                <Div className="row fade-in">
                                    <Div className="col-lg-6">
                                        <label className="form_field_label">Logradouro:</label>
                                        <input type="text" value={CRlogradouro} name="Logradouro" onChange={(e) => setCRLogradouro(e.target.value)} className="cs-form_field" required />
                                        <Spacing lg="20" md="20" />
                                    </Div>
                                    <Div className="col-lg-6">
                                        <label className="form_field_label">Bairro:</label>
                                        <input type="text" value={CRbairro} name="Bairro" onChange={(e) => setCRBairro(e.target.value)} className="cs-form_field" required />
                                        <Spacing lg="20" md="20" />
                                    </Div>
                                    <Div className="col-lg-6">
                                        <label className="form_field_label">Cidade:</label>
                                        <input type="text" value={CRcidade} name="Cidade" onChange={(e) => setCRCidade(e.target.value)} className="cs-form_field" required />
                                        <Spacing lg="20" md="20" />
                                    </Div>
                                    <Div className="col-lg-6">
                                        <label className="form_field_label">Estado:</label>
                                        <input type="text" value={CRestado} name="Estado" onChange={(e) => setCREstado(e.target.value)} className="cs-form_field" required />
                                        <Spacing lg="20" md="20" />
                                    </Div>
                                    <Div className="col-lg-6">
                                        <label className="form_field_label">Numero:</label>
                                        <input type="text" onChange={(e) => setCRNumero(e.target.value)} name="Numero" className="cs-form_field" required />
                                        <Spacing lg="20" md="20" />
                                    </Div>
                                    <Div className="col-lg-6">
                                        <label className="form_field_label">Complemento:</label>
                                        <input type="text" onChange={(e) => setCRComplemento(e.target.value)} name="Complemento" className="cs-form_field" required />
                                        <Spacing lg="20" md="20" />
                                    </Div>
                                </Div>
                            )}
                            {(CRnumero) && (
                                <Div className="row">
                                    <Div className="col-lg-6">
                                        <label className="form_field_label">Placa:</label>
                                        <InputMask
                                            mask="aaa-9*99"
                                            maskChar=""
                                            placeholder="___-____"
                                            onChange={(e) => setCRPlaca(e.target.value)}
                                        >
                                            {(inputProps) => <input {...inputProps} name="Placa" className="cs-form_field" required />}
                                        </InputMask>
                                        <Spacing lg="20" md="20" />
                                    </Div>
                                    <Div className="col-lg-6">
                                        <label className="form_field_label">Estado:</label>
                                        <select onChange={(e) => handleStateChange(e)} name="EstadoRetirada" className="cs-form_field" required>
                                            <option value="">Selecione um Estado</option>
                                            {CRstates.map(state => {
                                                return (
                                                    <option key={state.id} value={state.sigla}>{state.nome}</option>
                                                )
                                            })}
                                        </select>
                                        <Spacing lg="20" md="20" />
                                    </Div>
                                    <Div className="col-lg-6">
                                        <div className="d-flex align-items-center gap-4">
                                            <label className="form_field_label">Cidade:</label>
                                            {isSearchingCity && (
                                                <div className="mb-2">
                                                    <Loading size="2px" />
                                                </div>
                                            )}
                                        </div>
                                        <select onChange={(e) => setCRCidadeReserva(e.target.value)} disabled={isSearchingCity ? true : false} name="CidadeRetirada" className="cs-form_field" required>
                                            <option value="">Selecione uma Cidade</option>
                                            {CRcities.map(city => {
                                                return (
                                                    <option key={city.id} value={city.sigla}>{city.nome}</option>
                                                )
                                            })}
                                        </select>
                                        <Spacing lg="20" md="20" />
                                    </Div>
                                    <Div className="col-lg-6">
                                        <label className="form_field_label">Data Retirada:</label>
                                        <InputMask
                                            mask="99/99/9999"
                                            maskChar=""
                                            placeholder="__/__/____"
                                            onChange={(e) => setCRDataRetirada(e.target.value)}
                                        >
                                            {(inputProps) => <input {...inputProps} name="DataRetirada" className="cs-form_field" required />}
                                        </InputMask>
                                        <Spacing lg="20" md="20" />
                                    </Div>
                                    <Div className="col-lg-6">
                                        <label className="form_field_label">Hora Retirada:</label>
                                        <InputMask
                                            mask="99:99"
                                            maskChar=""
                                            placeholder="__:__"
                                            onChange={(e) => setCRHoraRetirada(e.target.value)}
                                        >
                                            {(inputProps) => <input {...inputProps} name="HoraRetirada" className="cs-form_field" required />}
                                        </InputMask>
                                        <Spacing lg="20" md="20" />
                                    </Div>
                                    <Div className="col-lg-6">
                                        <label className="form_field_label">Nome do Responsavel pela Locacao:</label>
                                        <input type="text" onChange={(e) => setCRResponsavelLocacao(e.target.value)} name="NomeResponsavelCartao" className="cs-form_field" required />
                                        <Spacing lg="20" md="20" />
                                    </Div>
                                    <Div className="col-lg-6">
                                        <label className="form_field_label">CPF (Responsavel pela Locacao):</label>
                                        <InputMask
                                            mask="999.999.999-99"
                                            maskChar=""
                                            placeholder="___.___.___-__"
                                            onChange={(e) => setCRCpfResponsavel(e.target.value)}
                                        >
                                            {(inputProps) => <input {...inputProps} name="CPFResponsavelCartao" className="cs-form_field" required />}
                                        </InputMask>
                                        <Spacing lg="20" md="20" />
                                    </Div>
                                    <Div className="col-lg-6">
                                        <label className="form_field_label">Diárias:</label>
                                        <input type="number" onChange={(e) => setCRDiarias(e.target.value)} name="QuantidadeDeDiarias" className="cs-form_field" required />
                                        <Spacing lg="20" md="20" />
                                    </Div>
                                    {CRisRefatorado === 0 && (
                                        <>
                                            <Div className="col-lg-6">
                                                <label className="form_field_label">Aviso de Sinistro da Empresa:</label>
                                                <FileInputPreview file={CRavisoSinistro} name="SinistroEmpresa" setFile={setCRavisoSinistro} />
                                                <Spacing lg="20" md="20" />
                                            </Div>
                                            <Div className="col-lg-6">
                                                <label className="form_field_label">Boletim de Ocorrência:</label>
                                                <FileInputPreview file={CRBoletimOcorrencia} name="BoletimOcorrencia" setFile={setCRBoletimOcorrencia} />
                                                <Spacing lg="20" md="20" />
                                            </Div>
                                            <Div className="col-lg-6">
                                                <label className="form_field_label">CRLV do Veículo:</label>
                                                <FileInputPreview file={CRcrlv} name="CRLVVeiculo" setFile={setCRcrlv} />
                                                <Spacing lg="20" md="20" />
                                            </Div>
                                            <Div className="col-lg-6">
                                                <label className="form_field_label">CNH do cliente:</label>
                                                <FileInputPreview file={CRcnh} name="CHNCliente" setFile={setCRcnh} />
                                                <Spacing lg="20" md="20" />
                                            </Div>
                                            <Div className="col-lg-6">
                                                <label className="form_field_label">Autorização do Reparo para a Oficina:</label>
                                                <FileInputPreview file={CRautorizacaoreparo} name="AutorizacaoReparo" setFile={setCRautorizacaoreparo} />
                                                <Spacing lg="20" md="20" />
                                            </Div>
                                        </>
                                    )}
                                </Div>
                            )}
                            {CRdiarias != null && (
                                <button className="fade-in cs-btn cs-style1 cs-type1" type='submit'>
                                    <span>{sendingButton}</span>
                                </button>
                            )}
                        </form>
                    )}
                </Div>
                :
                <Div>
                    <CheckCircle size={50} />
                    <h5 className="mt-3">Sucesso!</h5>
                    <p>Sua solicitação foi realizada com sucesso!<br />Em até 48 horas entraremos em contato para fazer a liberação do seu carro reserva!</p>
                    <button className="fade-in cs-btn cs-style1 cs-type1" type='button' onClick={(e) => window.location.reload()}>
                        <span>Realizar outra solicitacao</span>
                    </button>
                </Div>}
        </>
    )
}